import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from './store';
import { ISettingsResponse } from '../types/general';
import secureLocalStorage from "react-secure-storage";
//import { fetchCount } from './counterAPI';

export interface GeneralState {
    apiHost: string | null;
    appIsLoading: boolean;
    surveyDomain: string | null;
}

const initialState: GeneralState = {
    apiHost: null,
    appIsLoading: true,
    surveyDomain: null,
};

export const generalSlice = createSlice({
    name: 'general',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        addSettings: (state, action: PayloadAction<ISettingsResponse | null>) => {
            if (action.payload != null) {
                state.apiHost = action.payload.apiHost;
                state.surveyDomain = action.payload.surveyDomain;
                secureLocalStorage.setItem("setting", JSON.stringify(action.payload));
            }
        },
        setAppIsLoading: (state, action: PayloadAction<boolean>) => {
            state.appIsLoading = action.payload;
        }
    },
});

export const { addSettings, setAppIsLoading } = generalSlice.actions;

export const apiHost = (state: RootState) => state.general.apiHost;

export default generalSlice.reducer;
