import * as Bot from "../types/trader";
import { apiHost } from '../store/generalSlicer';
import { authKey } from '../store/accountSlicer';
import { store } from '../store/store';
import { handleErrorResponse } from '../api/interception';

export const apiTrader = {
    //getSurvey: (surveyId: string): Promise<KS.LoginRequest> => {
    //    return fetch(`v1/Account/Login?surveyId=` + surveyId) //v1/Insight/GetPage?respId=6126c0f26c39ca115d73cd9a
    //        .then(response => response.json() as Promise<KS.LoginRequest>);
    //},

    getStrategies: (): Promise<Bot.IStrategy[]> => {
        const _apiHost = apiHost(store.getState());
        const _authKey = authKey(store.getState());
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + _authKey },
        };
        
        return fetch(_apiHost + `/Trader/GetStrategies`, requestOptions)
            .then(response => handleErrorResponse(response)?.json() as Promise<Bot.IStrategy[]>);
    },

    addStrategy: (crypto: string, timeframe: number, apiType: number, className: string): Promise<Bot.IStrategy[]> => {
        const _apiHost = apiHost(store.getState());
        const _authKey = authKey(store.getState());
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': 'Bearer ' + _authKey, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                crypto: crypto,
                intervall: timeframe,
                apiType: apiType,
                class: className
            })
        };
        return fetch(_apiHost + `/Trader/AddStrategy`, requestOptions)
            .then(response => handleErrorResponse(response)?.json() as Promise<Bot.IStrategy[]>);
    },

    removeStrategy: (id: string): Promise<Bot.IStrategy[]> => {
        const _apiHost = apiHost(store.getState());
        const _authKey = authKey(store.getState());
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': 'Bearer ' + _authKey }
        };
        return fetch(_apiHost + `/Trader/RemoveStrategy?id=`+id, requestOptions)
            .then(response => handleErrorResponse(response)?.json() as Promise<Bot.IStrategy[]>);
    },

    getPositions: (): Promise<Bot.IPosition[]> => {
        const _apiHost = apiHost(store.getState());
        const _authKey = authKey(store.getState());
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + _authKey },
        };

        return fetch(_apiHost + `/Trader/GetOpenPositions`, requestOptions)
            .then(response => handleErrorResponse(response)?.json() as Promise<Bot.IPosition[]>);
    },

    getLog: (): Promise<Bot.ILog[]> => {
        const _apiHost = apiHost(store.getState());
        const _authKey = authKey(store.getState());
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + _authKey },
        };

        return fetch(_apiHost + `/Trader/GetLog`, requestOptions)
            .then(response => handleErrorResponse(response)?.json() as Promise<Bot.ILog[]>);
    },

    startSchedulers: (): Promise<void> => {
        const _apiHost = apiHost(store.getState());
        const _authKey = authKey(store.getState());
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': 'Bearer ' + _authKey },
        };

        return fetch(_apiHost + `/Trader/RunSchedulers`, requestOptions)
            .then(response => handleErrorResponse(response)?.json() as Promise<void>);
    },

    stopSchedulers: (): Promise<void> => {
        const _apiHost = apiHost(store.getState());
        const _authKey = authKey(store.getState());
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': 'Bearer ' + _authKey },
        };

        return fetch(_apiHost + `/Trader/StopSchedulers`, requestOptions)
            .then(response => handleErrorResponse(response)?.json() as Promise<void>);
    },

    //https://stackoverflow.com/questions/35206589/how-to-download-fetch-response-in-react-as-file
    //getExport: (surveyId: string): Promise<string> => {
    //    const _apiHost = apiHost(store.getState());
    //    const _authKey = authKey(store.getState());
    //    const requestOptions = {
    //        method: 'POST',
    //        headers: { 'Authorization': 'Bearer ' + _authKey, 'Content-Type': 'application/json' },
    //        body: JSON.stringify({
    //            surveyId: surveyId,
    //            panelId: null
    //        })
    //    };
        
    //    return fetch(_apiHost + `/v1/Survey/Export`, requestOptions)
    //        .then(response => handleErrorResponse(response)?.text() as Promise<string>);
    //},

    //getResponseByHash: (hash: string, surveyId: string): Promise<KS.PageResultDto> => {
    //    // Only load data if it's something we don't already have (and are not already loading)
    //    return fetch(`v1/Insight/GetPageByHash?surveyId=` + surveyId + `&hash=` + hash)
    //        .then(response => response.json() as Promise<KS.PageResultDto>);
    //},

    //getResponseById: (resId: string): Promise<KS.ResponseResultDto> => {
    //    // Only load data if it's something we don't already have (and are not already loading)
    //    return fetch(`v1/Insight/GetResponse?respId=` + resId)
    //        .then(response => response.json() as Promise<KS.ResponseResultDto>);
    //},

    //getNextPage: (responseId: string, values: KS.ValueDto[]): Promise<KS.NextResultDto> => {
    //    const requestOptions = {
    //        method: 'POST',
    //        headers: { 'Content-Type': 'application/json' },
    //        body: JSON.stringify({
    //            responseId: responseId,
    //            values: values
    //        })
    //    };
    //    return fetch(`v1/Insight/Next`, requestOptions)
    //        .then(response => response.json() as Promise<KS.NextResultDto>)
    //},

    //requestBack: (responseId: string): Promise<KS.PreviousResultDto> => {
    //    const requestOptions = {
    //        method: 'POST',
    //        headers: { 'Content-Type': 'application/json' },
    //        body: JSON.stringify({
    //            responseId: responseId
    //        })
    //    };
    //    return fetch(`v1/Insight/Previous`, requestOptions)
    //        .then(response => response.json() as Promise<KS.PreviousResultDto>);
    //}
};