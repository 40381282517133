import React from 'react';
import * as KS from "../../types/trader";
import { apiTrader } from '../../api/trader';
import { connect } from 'react-redux';
import { RootState } from '../../store/store';
import { accountSlice, AccountState } from '../../store/accountSlicer';
import { generalSlice, GeneralState } from '../../store/generalSlicer';
import { traderHelper, IStrategyLookup, ITimeframeLookup, IApiTypeLookup, ICryptoLookup } from '../../api/traderHelper';
import FileSaver from 'file-saver';
import FormatDate from '../../util/dateformat';

interface IStrategyListProps {
    //item: IG.ItemDiscrete,
    //answers: IG.ValueDto[]
}
interface IState {
    strategies: KS.IStrategy[],
    showList: boolean
}

type strategyListMergedProps =
    //AccountState &
    //GeneralState &
    RootState &
    typeof accountSlice.actions
    & typeof generalSlice.actions
    & IStrategyListProps;

class StrategyList extends React.PureComponent<strategyListMergedProps, IState> {
    constructor(props: strategyListMergedProps) {
        super(props);
        this.state = {
            strategies: [],
            showList: false
        };
    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        apiTrader.getStrategies().then(data => {
            //console.log(data[0].configs[0].symbol);
            this.setState({ strategies: data, showList: true });
        });
    }

    private startSchedulers() {
        apiTrader.startSchedulers();
    };

    private stopSchedulers() {
        apiTrader.stopSchedulers();
    };
    private addStrategy(e: React.MouseEvent, crypto: string, timeframe: number, apiType: number, className: string) {
        apiTrader.addStrategy(crypto, timeframe, apiType, className).then(data => {
            this.setState({ strategies: data});
        });
    }

    private removeStrategy(e: React.MouseEvent, id: string) {
        apiTrader.removeStrategy(id).then(data => {
            this.setState({ strategies: data});
        });
    }

    private lookForImplemented(tf: number, className: string, apiType: number, crypto: string) {
        var list = this.state.strategies.filter(
            (s) => { return s.class == className && s.intervall === tf && s.apiType === apiType && s.crypto == crypto }
        );
        if (list.length === 0)
            return (<span className='ks-link' onClick={(e) => this.addStrategy(e, crypto, tf, apiType, className)}>{crypto}</span>);
        else if (list.length > 1)
            "Error!"
        else
            return (<span className='ks-link ks-focus' onClick={(e) => this.removeStrategy(e, list[0].id)}>&nbsp;{crypto}&nbsp;</span>);
    }

    private mapCryptoList(tf: number, className: string, apiType: IApiTypeLookup) {
        return (
            <div>
                <span>{apiType.description}</span>
                <div>
                    {apiType.cryptos.map((c: ICryptoLookup) =>
                        <div key={c.name}>{this.lookForImplemented(tf, className, apiType.number, c.name)}</div> 
                    )}
                </div>
            </div>
        );
    };

    public render() {
        //console.log(this.props);
        return (
            <section>
                <h1>Strategies</h1>

                <div className="ks-table">
                    <div className="ks-table-header">
                        <div>Strategy</div>
                        {traderHelper.getAvailableTimeframes().map((t: ITimeframeLookup) =>
                            <div key={t.number}>{t.description}</div>
                        )}
                    </div>
                    {(this.state.showList) ? traderHelper.getAvailableStrategies().map((s: IStrategyLookup) =>
                        <div key={s.class}>
                            <div>{s.class}</div>
                            {traderHelper.getAvailableTimeframes().map((t: ITimeframeLookup) =>
                                <div key={t.number}>
                                    {traderHelper.getAvailableApiTypes().map((a: IApiTypeLookup) =>
                                        <div key={a.number}>{this.mapCryptoList(t.number, s.class, a)}<br /></div>
                                    )}
                                </div>
                            )}
                        </div>
                    ) : null}
                </div>

                <a onClick={() => this.startSchedulers()} className="ks-link">Start Schedulers</a>&nbsp;| &nbsp;
                <a onClick={() => this.stopSchedulers()} className="ks-link">Stop Schedulers</a>
            </section>
        );
    }
}

const mapStateToProps = (state: RootState) => state;

const mapDispatchToProps = {
    
};

export default connect(mapStateToProps, mapDispatchToProps)(StrategyList as any);

//export default connect(
//    (state: RootState) => state.account, // Selects which state properties are merged into the component's props
//    accountSlice.actions && generalSlice.actions // Selects which action creators are merged into the component's props
//)(Survey as any);

//export const SurveyAlt = () => {
//    const dispatch = useAppDispatch();
//    const [surveys, setSurveys] = useState([] as KS.SurveyResponse[]);
//    const [myMap, setMyMap] = useState(new Map());
//    const updateMap = (k: any, v: any) => {
//        setMyMap(new Map(myMap.set(k, v)));
//    }
//    let listItems: JSX.Element[] = [];
//    useEffect(() => {
//        apiSurvey.getSurveys().then(data => {
//            //dispatch(addApiHost(data.apiHost));
//            //dispatch(setAppIsLoading(false));
//            setSurveys(data);
//            //updateMap()
//            listItems = data.map((survey) =>
//                <li>{survey.name}</li>
//            );
//            console.log(listItems);
//            console.log(data);
//        });
//    }, []);

//    return (
//        <section>
//            <h1>Surveys</h1>
//            <ul>
//                {[...myMap.keys()].map(k => (
//                    <li key={k}>myMap.get(k)</li>
//                ))}
//            </ul>
//            <ul>
//                {listItems}
//            </ul>
//        </section>
//    );
//};