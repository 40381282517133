import * as Bot from "../types/trader";
import { apiHost } from '../store/generalSlicer';
import { authKey } from '../store/accountSlicer';
import { store } from '../store/store';
import { handleErrorResponse } from '../api/interception';

export interface IStrategyLookup {
    class: string;
}

export interface ITimeframeLookup {
    number: number;
    description: string;
}

export interface IApiTypeLookup {
    number: number;
    description: string;
    cryptos: ICryptoLookup[];
}

export interface ICryptoLookup {
    name: string
}

export const traderHelper = {

    //timeframeNumberToString: (num: number): string|undefined => {
    //    switch (num) {
    //        case 10: return "5 Min";
    //        case 20: return "15 Min"
    //        case 40: return "1 Hour"
    //        case 50: return "4 Hour"
    //        case 70: return "1 Day"
    //    }
    //},

    apiTypeToString: (type: number): string|undefined => {
        switch (type) {
            case 0: return "USDⓈ-M Futures";
            case 1: return "COIN-M Futures";
        }
    },


    getAvailableApiTypes: (): IApiTypeLookup[] => {
        let list: IApiTypeLookup[] = [];
        list.push({ number: 0, description: "USDⓈ-M Futures", cryptos: [{ name: "BTCUSDT" }, { name: "AVAXUSDT" }, { name: "SOLUSDT" }, { name: "NEARUSDT" }, { name: "ADAUSDT" }, { name: "TRXUSDT" }, { name: "ROSEUSDT" }] });
        list.push({ number: 1, description: "COIN-M Futures", cryptos: [{ name: "BTCUSD_PERP" }] });
        return list;
    },

    getAvailableStrategies: (): IStrategyLookup[] => {
        let list: IStrategyLookup[] = [];
        list.push({ class: "Ema50200Crossover"});
        list.push({ class: "EmaFanLong" });
        list.push({ class: "EmaMeanReversion" });
        list.push({ class: "RsiBB" });
        list.push({ class: "RsiBBOutBreakShort" });
        list.push({ class: "SevenDaysLow" });
        list.push({ class: "StochRsiSupertrendLongI" });
        //list.push({ class: "StochRsiSupertrendShortI" });
        return list;
    },

    getAvailableTimeframes: (): ITimeframeLookup[] => {
        let list: ITimeframeLookup[] = [];
        list.push({ number: 10, description: "5 min" });
        list.push({ number: 20, description: "15 min" });
        list.push({ number: 40, description: "1 Hour" });
        list.push({ number: 50, description: "4 Hour" });
        list.push({ number: 70, description: "1 Day" });
        return list;
    }

};