const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, '0');
}

export function FormatDateFromString (dateStr: string) {
    const date = new Date(dateStr);
    return FormatDate(date);
}

export function FormatDateTimeFromString(dateStr: string) {
    const date = new Date(dateStr);
    return FormatDateTime(date);
}

export function FormatDateTime(date: Date) {
    let datePart = [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
    ].join('-');
    let timePart = [
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
    ].join(':');
    return datePart + " " + timePart;
}

export default function FormatDate(date: Date) {
    return (
        [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('-')
    );
}