import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { store } from './store/store';
import { Provider } from 'react-redux'; 
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import reportWebVitals from './reportWebVitals';

/*
 * https://www.youtube.com/watch?v=88WeBeV4HqU&t=299s&ab_channel=THMJ
 * READ: https://redux.js.org/tutorials/essentials/part-2-app-structure
 * */ 

let baseUrl = "";
const res = document.getElementsByTagName('base')[0].getAttribute('href')
if (res != null)
    baseUrl = res;
const rootElement = document.getElementById('root') || document.createElement("root");

const root = createRoot(rootElement); 
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter basename={baseUrl}>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();