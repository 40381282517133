import React from 'react';
import * as KS from "../../types/trader";
import { apiTrader } from '../../api/trader';
import { connect } from 'react-redux';
import { RootState } from '../../store/store';
import { accountSlice, AccountState } from '../../store/accountSlicer';
import { generalSlice, GeneralState } from '../../store/generalSlicer';
import FileSaver from 'file-saver';
import FormatDate from '../../util/dateformat';
import StrategyList from './StrategyList';
import PositionList from './PositionList';
import SchedulerLog from './SchedulerLog';

interface IDashboardProps {
    //item: IG.ItemDiscrete,
    //answers: IG.ValueDto[]
}
interface IState {
    //surveys: KS.IStrategy[],
}

type dashboardMergedProps =
    //AccountState &
    //GeneralState &
    RootState &
    typeof accountSlice.actions
    & typeof generalSlice.actions
    & IDashboardProps;

class Dashboard extends React.PureComponent<dashboardMergedProps, IState> {
    constructor(props: dashboardMergedProps) {
        super(props);
        this.state = {
            //surveys: []
        };
    }

    public render() {
        return (
            <React.Fragment>
                <PositionList />
                <br /><br />
                <StrategyList />
                <br /><br/>
                <SchedulerLog />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: RootState) => state;

const mapDispatchToProps = {
    
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard as any);

//export default connect(
//    (state: RootState) => state.account, // Selects which state properties are merged into the component's props
//    accountSlice.actions && generalSlice.actions // Selects which action creators are merged into the component's props
//)(Survey as any);

//export const SurveyAlt = () => {
//    const dispatch = useAppDispatch();
//    const [surveys, setSurveys] = useState([] as KS.SurveyResponse[]);
//    const [myMap, setMyMap] = useState(new Map());
//    const updateMap = (k: any, v: any) => {
//        setMyMap(new Map(myMap.set(k, v)));
//    }
//    let listItems: JSX.Element[] = [];
//    useEffect(() => {
//        apiSurvey.getSurveys().then(data => {
//            //dispatch(addApiHost(data.apiHost));
//            //dispatch(setAppIsLoading(false));
//            setSurveys(data);
//            //updateMap()
//            listItems = data.map((survey) =>
//                <li>{survey.name}</li>
//            );
//            console.log(listItems);
//            console.log(data);
//        });
//    }, []);

//    return (
//        <section>
//            <h1>Surveys</h1>
//            <ul>
//                {[...myMap.keys()].map(k => (
//                    <li key={k}>myMap.get(k)</li>
//                ))}
//            </ul>
//            <ul>
//                {listItems}
//            </ul>
//        </section>
//    );
//};