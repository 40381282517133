import * as KS from "../types/general";

export const apiGeneral = {
    //getSurvey: (surveyId: string): Promise<KS.LoginRequest> => {
    //    return fetch(`v1/Account/Login?surveyId=` + surveyId) //v1/Insight/GetPage?respId=6126c0f26c39ca115d73cd9a
    //        .then(response => response.json() as Promise<KS.LoginRequest>);
    //},

    getSettings: (): Promise<KS.ISettingsResponse> => {
        return fetch(`/api/general/getsettings`)
            .then(response => response.json() as Promise<KS.ISettingsResponse>);
    },

    //getResponseByHash: (hash: string, surveyId: string): Promise<KS.PageResultDto> => {
    //    // Only load data if it's something we don't already have (and are not already loading)
    //    return fetch(`v1/Insight/GetPageByHash?surveyId=` + surveyId + `&hash=` + hash)
    //        .then(response => response.json() as Promise<KS.PageResultDto>);
    //},

    //getResponseById: (resId: string): Promise<KS.ResponseResultDto> => {
    //    // Only load data if it's something we don't already have (and are not already loading)
    //    return fetch(`v1/Insight/GetResponse?respId=` + resId)
    //        .then(response => response.json() as Promise<KS.ResponseResultDto>);
    //},

    //getNextPage: (responseId: string, values: KS.ValueDto[]): Promise<KS.NextResultDto> => {
    //    const requestOptions = {
    //        method: 'POST',
    //        headers: { 'Content-Type': 'application/json' },
    //        body: JSON.stringify({
    //            responseId: responseId,
    //            values: values
    //        })
    //    };
    //    return fetch(`v1/Insight/Next`, requestOptions)
    //        .then(response => response.json() as Promise<KS.NextResultDto>)
    //},

    //requestBack: (responseId: string): Promise<KS.PreviousResultDto> => {
    //    const requestOptions = {
    //        method: 'POST',
    //        headers: { 'Content-Type': 'application/json' },
    //        body: JSON.stringify({
    //            responseId: responseId
    //        })
    //    };
    //    return fetch(`v1/Insight/Previous`, requestOptions)
    //        .then(response => response.json() as Promise<KS.PreviousResultDto>);
    //}
};