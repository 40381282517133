import React from 'react';
import * as KS from "../../types/trader";
import { apiTrader } from '../../api/trader';
import { connect } from 'react-redux';
import { RootState } from '../../store/store';
import { accountSlice, AccountState } from '../../store/accountSlicer';
import { generalSlice, GeneralState } from '../../store/generalSlicer';
import FileSaver from 'file-saver';
import { FormatDateTimeFromString } from '../../util/dateformat';

interface ISchedulerLogProps {
    //item: IG.ItemDiscrete,
    //answers: IG.ValueDto[]
}
interface IState {
    logs: KS.ILog[],
}

type schedulerLogMergedProps =
    //AccountState &
    //GeneralState &
    RootState &
    typeof accountSlice.actions
    & typeof generalSlice.actions
    & ISchedulerLogProps;

class StrategyList extends React.PureComponent<schedulerLogMergedProps, IState> {
    constructor(props: schedulerLogMergedProps) {
        super(props);
        this.state = {
            logs: []
        };
    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        apiTrader.getLog().then(data => {
            this.setState({ logs:data});
        });
    }

    public render() {
        return (
            <section>
                <h1>Log From Scheduler</h1>
                <div className="ks-table">
                    <div className="ks-table-header">
                        <div>Date</div>
                        <div>Message</div>
                    </div>
                    {this.state.logs.map((s: KS.ILog, index) =>
                        <div key={index}>
                            <div>{FormatDateTimeFromString(s.date)}</div>
                            <div>{s.message}</div>
                        </div>
                    )}
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state: RootState) => state;

const mapDispatchToProps = {
    
};

export default connect(mapStateToProps, mapDispatchToProps)(StrategyList as any);

//export default connect(
//    (state: RootState) => state.account, // Selects which state properties are merged into the component's props
//    accountSlice.actions && generalSlice.actions // Selects which action creators are merged into the component's props
//)(Survey as any);

//export const SurveyAlt = () => {
//    const dispatch = useAppDispatch();
//    const [surveys, setSurveys] = useState([] as KS.SurveyResponse[]);
//    const [myMap, setMyMap] = useState(new Map());
//    const updateMap = (k: any, v: any) => {
//        setMyMap(new Map(myMap.set(k, v)));
//    }
//    let listItems: JSX.Element[] = [];
//    useEffect(() => {
//        apiSurvey.getSurveys().then(data => {
//            //dispatch(addApiHost(data.apiHost));
//            //dispatch(setAppIsLoading(false));
//            setSurveys(data);
//            //updateMap()
//            listItems = data.map((survey) =>
//                <li>{survey.name}</li>
//            );
//            console.log(listItems);
//            console.log(data);
//        });
//    }, []);

//    return (
//        <section>
//            <h1>Surveys</h1>
//            <ul>
//                {[...myMap.keys()].map(k => (
//                    <li key={k}>myMap.get(k)</li>
//                ))}
//            </ul>
//            <ul>
//                {listItems}
//            </ul>
//        </section>
//    );
//};