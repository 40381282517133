import React, { Component } from 'react';
import { sendLogout } from '../../logout';
import { apiHost } from '../../store/generalSlicer';
import { authKey } from '../../store/accountSlicer';
import { store } from '../../store/store';

import "./Layout.css";

type LayoutProps = {
    children: React.ReactNode; //type children
};

export const gotoHangfire = () => {
    const _apiHost = apiHost(store.getState());
    const _authKey = authKey(store.getState());
    window.location.href = _apiHost + '/hangfire?access_token=' + _authKey;
};

export const Layout = (props: LayoutProps) => {
    
    return (
        <div id="ks-layout-root">
            <header>
                <a onClick={() => sendLogout()} className="ks-logout">Logout</a>
                <a onClick={() => gotoHangfire()} className="ks-logout">Hangfire</a>
            </header>
            <main>
                {props.children}
            </main>
            <footer>

                <div className="copyright">&copy;KeenSight, 2022</div>
            </footer>
        </div>
    );
};