import React from 'react';
import * as KS from "../../types/trader";
import { apiTrader } from '../../api/trader';
import { connect } from 'react-redux';
import { RootState } from '../../store/store';
import { accountSlice, AccountState } from '../../store/accountSlicer';
import { generalSlice, GeneralState } from '../../store/generalSlicer';
import FileSaver from 'file-saver';
import FormatDate from '../../util/dateformat';

interface IPositionListProps {
    //item: IG.ItemDiscrete,
    //answers: IG.ValueDto[]
}
interface IState {
    positions: KS.IPosition[],
}

type positionListMergedProps =
    //AccountState &
    //GeneralState &
    RootState &
    typeof accountSlice.actions
    & typeof generalSlice.actions
    & IPositionListProps;

class PositionList extends React.PureComponent<positionListMergedProps, IState> {
    constructor(props: positionListMergedProps) {
        super(props);
        this.state = {
            positions: []
        };
    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        apiTrader.getPositions().then(data => {
            //console.log(data[0].configs[0].symbol);
            this.setState({positions:data});
        });
    }

    public render() {
        //console.log(this.props);
        return (
            <section>
                <h1>Positions</h1>
                <div className="ks-table">
                    <div className="ks-table-header">
                        <div>Id</div>
                        <div>Start</div>
                        <div>Quantity</div>
                        <div>Intervall</div>
                        <div>Symbol</div>
                    </div>
                    {this.state.positions.map((s: KS.IPosition) =>
                        <div key={s.id}>
                            <div>{s.id}/{s.strategy}</div>
                            <div>{s.created.toLocaleString()}</div>
                            <div>{s.quantity}</div>
                            <div>{s.intervall}</div>
                            <div>{s.symbol}</div>
                            <div>
                                {/*<span className="ks-link" onClick={(e) => this.onExport(e, s.id)}>Export</span>*/}
                                Do Actions Here
                            </div>
                        </div>
                    )}
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state: RootState) => state;

const mapDispatchToProps = {
    
};

export default connect(mapStateToProps, mapDispatchToProps)(PositionList as any);
